
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
// import MailTemplateEditModal from "@/views/admin/MailTemplateEditModal.vue";

export default defineComponent({
  name: "AdminZones",
  //   components: {   MailTemplateEditModal },
  setup() {
    const store = useStore();

    let search = ref("");
    let currentPageURL = ref("");

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });
    let mailTemplates = computed(() => {
      return store.getters[Modules.MASTER + "getMailTemplates"];
    });

    var mail_id = "";
    var mail_content = "";
    var mail_type = "";
    var subject = "";

    // let filteredEventEnvironments = computed(() =>
    //   Object.prototype.hasOwnProperty.call(eventEnvironments.value, "data")
    //     ? eventEnvironments.value.data.filter(
    //         (item) =>
    //           item.name.toUpperCase().includes(search.value.toUpperCase()) ||
    //           item.code.toUpperCase().includes(search.value.toUpperCase()) ||
    //           !search.value
    //       )
    //     : []
    // );

    onMounted(() => {
      setCurrentPageTitle("Templates");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }
    function fetchRecords() {
      store.dispatch(Modules.MASTER + Actions.FETCH_MAIL_TEMPLATES, {
        search: search.value,
        url: currentPageURL.value,
      });
    }

    let errors: any = {
      name: "",
      code: "",
    };
    let name: any = "";
    let code: any = "";
    const onSubmit = (values) => {
      console.log("Test submit ");
    };

    function viewMailBody(id) {
      (document.querySelector("#maildata") as HTMLInputElement).innerHTML =
        "Loading...";
      store
        .dispatch(Modules.MASTER + Actions.GET_MAIL_TEMPLATE, {
          id: id,
        })
        .then((resp) => {
          (document.querySelector("#maildata") as HTMLInputElement).innerHTML =
            resp[0].mail_content;
        });
    }
    function editForm(id) {
      (document.querySelector("#mail_id") as HTMLInputElement).value = "";
      (document.querySelector("#subject") as HTMLInputElement).value =
        "Loading...";
      (document.querySelector("#mail_type") as HTMLInputElement).value =
        "Loading...";
      (document.querySelector("#mail_content") as HTMLInputElement).value =
        "Loading...";
      store
        .dispatch(Modules.MASTER + Actions.GET_MAIL_TEMPLATE, {
          id: id,
        })
        .then((resp) => {
          (document.querySelector("#mail_id") as HTMLInputElement).value =
            resp[0].id;
          (document.querySelector("#subject") as HTMLInputElement).value =
            resp[0].subject;
          (document.querySelector("#mail_type") as HTMLInputElement).value =
            resp[0].mail_type;
          (document.querySelector("#mail_content") as HTMLInputElement).value =
            resp[0].mail_content;
        });
    }
    function saveMailTemplate(values) {
      let mail_id_v = (document.querySelector("#mail_id") as HTMLInputElement)
        .value;
      let mail_type_v = (
        document.querySelector("#mail_type") as HTMLInputElement
      ).value;
      let mail_content_v = (
        document.querySelector("#mail_content") as HTMLInputElement
      ).value;
      let subject_v = (document.querySelector("#subject") as HTMLInputElement)
        .value;
      store
        .dispatch(Modules.MASTER + Actions.UPDATE_MAIL_TEMPLATE, {
          template_name: mail_type_v,
          template_code: mail_content_v,
          subject: subject_v,
          id: mail_id_v,
        })
        .then((resp) => {
          refresh();
        });
    }
    return {
      formatDateTime,
      saveMailTemplate,
      search,
      isLoading,
      mailTemplates,
      refresh,
      searchNow,
      gotoPage,
      onSubmit,
      errors,
      name,
      code,
      editForm,
      mail_content,
      mail_type,
      mail_id,
      viewMailBody,
      subject,
    };
  },
});
